import { useEffect, useState } from 'react';
import { useConstants } from 'hooks/useConstants';
import Content from './Content';
import Description from './Description';
import Value from './Value';

const Transparency = () => {
  const constantsData = useConstants();
  const [constants, setConstants] = useState(null);
  useEffect(() => {
    if (!constantsData?.isLoading && constantsData !== null) {
      setConstants(constantsData?.data?.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constantsData?.isLoading, constantsData?.data, constantsData]);

  return (
    <div className={`bg-[#263238]  h-fit flex flex-col w-full pt-[90px]`}>
      <Description />
      <Value constants={constants}/>
      <Content constants={constants}/>
    </div>
  );
};

export default Transparency;
