const Content = () => {
  return (
    <div
      className={` w-full relative bg-blue-800 overflow-hidden flex flex-col`}
    >
      <div className="flex items-center justify-center w-full gap-0 py-10 bg-gray-200">
        <div className="max-w-[1200px] w-[90%] flex items-stretch justify-evenly font-inter  flex-col gap-5 text-lg">
          <p className="text-xl font-bold font-poppins">
            1. ACCEPTANCE OF TERMS
          </p>

          <p>
            1.1. By accessing or using the Nexus Pro platform, Products and
            services, including but not limited to the Nexus Pro tokens EU/US
            and US/EU ("Digital Products "), you agree to comply with and be
            bound by these Terms of Use ("Terms"). If you do not agree to these
            Terms, do not use the Services.
          </p>

          <p>
            1.2. Nexus Pro reserves the right to modify these Terms at any time.
            Changes will become effective immediately upon posting on the Nexus
            Pro website/apps. Your continued use of the Services constitutes
            acceptance of the modified Terms.
          </p>

          <p className="text-xl font-bold font-poppins">2. USE OF SERVICES</p>

          <p>
            2.1. Nexus Pro grants you a limited, non-exclusive,
            non-transferable, and revocable license to access and use the
            Services for personal or business purposes.
          </p>

          <p>
            2.2. You agree to comply with all applicable laws and regulations
            when using the Services.
          </p>

          <p>
            2.3. You are responsible for maintaining the confidentiality of your
            account information and are fully responsible for all activities
            that occur under your account.
          </p>

          <p className="text-xl font-bold font-poppins">
            3. INTELLECTUAL PROPERTY
          </p>

          <p>
            3.1. Nexus Pro retains all rights, title, and interest in and to the
            Services, including patents, copyrights, trademarks, trade secrets,
            and other intellectual property rights.
          </p>

          <p>
            3.2. You may not reproduce, distribute, modify, create derivative
            works of, publicly display, publicly perform, republish, download,
            store, or transmit any of the material on the Nexus Pro website.
          </p>

          <p className="text-xl font-bold font-poppins">4. TECHNOLOGY</p>

          <p>
            4.1. Nexus Pro may use advanced technology, including but not
            limited to blockchain technology, to provide its Services.
          </p>

          <p>
            4.2. Nexus Pro reserves all rights to its technology and
            intellectual property.
          </p>

          <p className="text-xl font-bold font-poppins">5. DISCLAIMERS</p>

          <p>
            5.1. Nexus Pro does not provide financial, investment, or legal
            advice. Users should conduct their own research and seek
            professional advice.
          </p>

          <p>
            5.2. Nexus Pro is not responsible for any loss or damage resulting
            from the use of the Services, including but not limited to market
            fluctuations, technical failures, and unauthorized access.
          </p>

          <p className="text-xl font-bold font-poppins">
            6. P2P NETWORK AND DECENTRALIZED WALLET
          </p>

          <p>
            6.1. Nexus Pro tokens EU/US and US/EU are connected to a
            peer-to-peer (P2P) network, allowing users to control their funds
            for activities such as payments, lending, transfers, swaps, and
            trades.
          </p>

          <p>
            6.2. Nexus Pro provides a decentralized wallet for secure and safe
            asset management.
          </p>

          <p className="text-xl font-bold font-poppins">
            7. DISCLAIMER OF WARRANTIES
          </p>

          <p>
            7.1. THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
            ANY WARRANTIES, EXPRESS OR IMPLIED. NEXUS PRO DISCLAIMS ALL
            WARRANTIES, INCLUDING MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, AND NON-INFRINGEMENT.
          </p>

          <p className="text-xl font-bold font-poppins">
            8. LIMITATION OF LIABILITY
          </p>

          <p>
            8.1. IN NO EVENT SHALL NEXUS PRO BE LIABLE FOR ANY DIRECT, INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING
            BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES,
            LOSS OF USE, DATA, OR PROFITS.
          </p>

          <p>
            8.2. NEXUS PRO'S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION
            WITH THESE TERMS OR THE USE OF THE SERVICES SHALL NOT EXCEED THE
            AMOUNT PAID BY YOU TO NEXUS PRO IN THE SIX MONTHS PRIOR TO THE EVENT
            GIVING RISE TO LIABILITY.
          </p>

          <p className="text-xl font-bold font-poppins">
            9. GOVERNING LAW AND DISPUTE RESOLUTION
          </p>

          <p>
            9.1. These Terms are governed by the laws of Switzerland. Any
            dispute arising out of or in connection with these Terms shall be
            subject to the exclusive jurisdiction of the Swiss courts.
          </p>

          <p className="text-xl font-bold font-poppins">10. MISCELLANEOUS</p>

          <p>
            10.1. These Terms constitute the entire agreement between you and
            Nexus Pro regarding the use of the Services.
          </p>

          <p>
            10.2. If any provision of these Terms is deemed invalid or
            unenforceable, that provision shall be deemed severable and shall
            not affect the validity and enforceability of the remaining
            provisions.
          </p>

          <p className="text-xl font-bold font-poppins">
            11. CONTACT INFORMATION
          </p>

          <p>
            If you have any questions about these Terms, please contact Nexus
            Pro at info@nexuspro.io By using the Services, you acknowledge that
            you have read, understood, and agree to be bound by these Terms of
            Use.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Content;
