import React, { useEffect, useState } from "react";
import PriceBox from "./PriceBox";
import ExchangeTableModal from "./ExchangeTableModal";
import { usePriceOtherPlatform } from "hooks/usePriceOtherPlatform";
import { useNexusTokenPrice } from "hooks/useNexusTokenPrice";

const assets = ["BTC", "ETH", "USDT", "USDC", "WBTC", "WETH"];
const assetsUrl = {
  BTC: "https://assets.coingecko.com/coins/images/1/standard/bitcoin.png?1696501400",
  ETH: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
  USDT: "https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661",
  USDC: "https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694",
  WBTC: "https://assets.coingecko.com/coins/images/7598/standard/wrapped_bitcoin_wbtc.png?1696507857",
  WETH: "https://assets.coingecko.com/coins/images/2518/standard/weth.png?1696503332",
};
const CryptoDashboard = () => {
  const priceOtherPlatform = usePriceOtherPlatform();
  const tokenPrice = useNexusTokenPrice();

  const [priceOtherPlatformData, setPriceOtherPlatformData] = useState(null);
  const [tokenPriceData, setTokenPriceData] = useState(null);

  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (priceOtherPlatform !== null && !priceOtherPlatform?.isLoading) {
      setPriceOtherPlatformData(priceOtherPlatform?.data?.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceOtherPlatform?.isLoading]);

  useEffect(() => {
    if (tokenPrice !== null && !tokenPrice?.isLoading) {
      setTokenPriceData(tokenPrice?.data?.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenPrice?.isLoading]);

  const openModal = (symbol) => {
    setSelectedSymbol(symbol);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onClickTrade = () => {
    window.open(`${process.env.REACT_APP_DASHBOARD_URL}`, "_blank");
  };
  return (
    <div className="max-w-9xl mx-auto p-4 text-center">
      <h1 className="text-2xl font-semibold mb-6">
        Price Up on Nexus Pro Dashboard{" "}
      </h1>
      <div className="relative flex lg:flex-row flex-col items-center mb-4 gap-4">
        {assets.map((token) => {
          return (
            <PriceBox
              key={token}
              imgUrl={assetsUrl[token]}
              token={token}
              priceOtherPlatform={
                priceOtherPlatformData && !priceOtherPlatformData.isLoading
                  ? priceOtherPlatformData[token].average
                  : ""
              }
              price={
                tokenPriceData && !tokenPriceData.isLoading
                  ? tokenPriceData[token]
                  : ""
              }
              openModal={openModal}
              onClickTrade={onClickTrade}
            />
          );
        })}
      </div>
      {isModalOpen && (
        <ExchangeTableModal
          token={selectedSymbol}
          priceOtherPlatform={
            priceOtherPlatformData && !priceOtherPlatformData.isLoading
              ? priceOtherPlatformData[selectedSymbol]
              : null
          }
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default CryptoDashboard;
