import React from "react";
import {
  fixDigitFilter,
  formatCurrencyWithDecimalString,
} from "utils/formatNumber";

const PriceBox = ({
  token = "",
  imgUrl = "",
  priceOtherPlatform = "",
  price = "",
  openModal,
  onClickTrade,
}) => {
  const plusPercentage = fixDigitFilter(
    price > priceOtherPlatform
      ? ((price - priceOtherPlatform) * 100) / price
      : 0
  );
  return (
    <div className="border border-gray-300 p-4 rounded-lg bg-white shadow-md w-full max-w-sm min-w-64">
      <h2 className="text-lg font-medium">
        <div className="flex items-center justify-center mb-2">
          <img src={imgUrl} alt={`${imgUrl} logo`} className="w-6 h-6 mr-2" />
          <h2 className="text-lg font-medium">{token} Price:</h2>
        </div>
        <span
          className="text-gray-900 cursor-pointer underline"
          onClick={() => openModal(token)}
        >
          {formatCurrencyWithDecimalString(priceOtherPlatform)}
        </span>
      </h2>
      <span className="text-sm text-gray-600">Get +{plusPercentage}%</span>
      <div className="text-xl font-semibold">
        <p> Sell Your {token} For </p>
        <span className="text-blue-600 ">
          {formatCurrencyWithDecimalString(price)}
        </span>
      </div>
      <button
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        onClick={onClickTrade}
      >
        Trade Now
      </button>
    </div>
  );
};

export default PriceBox;
