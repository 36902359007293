import React from "react";
import { formatCurrencyWithDecimalString } from "utils/formatNumber";

const exchangeData = {
  BTC: [
    {
      key: "binance",
      platformName: "Binance",
      pair: "BTC/USDT",
      img: "https://assets.coingecko.com/coins/images/14108/standard/Binance-bitcoin.png?1696513829",
    },
    {
      key: "coinbase",
      platformName: "Coinbase",
      pair: "BTC/USD",
      img: "https://assets.coingecko.com/markets/images/23/large/Coinbase_Coin_Primary.png?1706864258",
    },
    {
      key: "kraken",
      platformName: "Kraken",
      pair: "BTC/USD",
      img: "https://assets.coingecko.com/markets/images/29/large/kraken.jpg?1706864265",
    },
    {
      key: "bitfinex",
      platformName: "Bitfinex",
      pair: "BTC/USD",
      img: "https://assets.coingecko.com/markets/images/4/large/BItfinex.png?1706864245",
    },
    {
      key: "gateio",
      platformName: "Gate.io",
      pair: "BTC/ USDT",
      img: "https://assets.coingecko.com/markets/images/60/large/gate_io_logo1.jpg?1706864280",
    },
    {
      key: "mexc",
      platformName: "MEXC",
      pair: "BTC/USDT",
      img: "https://assets.coingecko.com/markets/images/409/large/MEXC_logo_square.jpeg?1706864416",
    },
  ],
  ETH: [
    {
      key: "binance",
      platformName: "Binance",
      pair: "ETH/USDT",
      img: "https://assets.coingecko.com/coins/images/14108/standard/Binance-bitcoin.png?1696513829",
    },
    {
      key: "coinbase",
      platformName: "Coinbase",
      pair: "ETH/USD",
      img: "https://assets.coingecko.com/markets/images/23/large/Coinbase_Coin_Primary.png?1706864258",
    },
    {
      key: "kraken",
      platformName: "Kraken",
      pair: "ETH/USD",
      img: "https://assets.coingecko.com/markets/images/29/large/kraken.jpg?1706864265",
    },
    {
      key: "bitfinex",
      platformName: "Bitfinex",
      pair: "ETH/USD",
      img: "https://assets.coingecko.com/markets/images/4/large/BItfinex.png?1706864245",
    },
    {
      key: "gateio",
      platformName: "Gate.io",
      pair: "ETH/ USDT",
      img: "https://assets.coingecko.com/markets/images/60/large/gate_io_logo1.jpg?1706864280",
    },
    {
      key: "mexc",
      platformName: "MEXC",
      pair: "ETH/USDT",
      img: "https://assets.coingecko.com/markets/images/409/large/MEXC_logo_square.jpeg?1706864416",
    },
  ],
  USDT: [
    {
      key: "binance",
      platformName: "Binance",
      pair: "USDT/USD",
      img: "https://assets.coingecko.com/coins/images/14108/standard/Binance-bitcoin.png?1696513829",
    },
    {
      key: "coinbase",
      platformName: "Coinbase",
      pair: "USDT/USD",
      img: "https://assets.coingecko.com/markets/images/23/large/Coinbase_Coin_Primary.png?1706864258",
    },
    {
      key: "kraken",
      platformName: "Kraken",
      pair: "USDT/USD",
      img: "https://assets.coingecko.com/markets/images/29/large/kraken.jpg?1706864265",
    },
    {
      key: "bitfinex",
      platformName: "Bitfinex",
      pair: "USDT/USD",
      img: "https://assets.coingecko.com/markets/images/4/large/BItfinex.png?1706864245",
    },
    {
      key: "gateio",
      platformName: "Gate.io",
      pair: "USDT/ USD",
      img: "https://assets.coingecko.com/markets/images/60/large/gate_io_logo1.jpg?1706864280",
    },
    {
      key: "mexc",
      platformName: "MEXC",
      pair: "USDT/USD",
      img: "https://assets.coingecko.com/markets/images/409/large/MEXC_logo_square.jpeg?1706864416",
    },
  ],
  USDC: [
    {
      key: "binance",
      platformName: "Binance",
      pair: "USDC/USDT",
      img: "https://assets.coingecko.com/coins/images/14108/standard/Binance-bitcoin.png?1696513829",
    },
    {
      key: "coinbase",
      platformName: "Coinbase",
      pair: "USDC/USD",
      img: "https://assets.coingecko.com/markets/images/23/large/Coinbase_Coin_Primary.png?1706864258",
    },
    {
      key: "kraken",
      platformName: "Kraken",
      pair: "USDC/USD",
      img: "https://assets.coingecko.com/markets/images/29/large/kraken.jpg?1706864265",
    },
    {
      key: "bitfinex",
      platformName: "Bitfinex",
      pair: "USDC/USD",
      img: "https://assets.coingecko.com/markets/images/4/large/BItfinex.png?1706864245",
    },
    {
      key: "gateio",
      platformName: "Gate.io",
      pair: "USDC/ USDT",
      img: "https://assets.coingecko.com/markets/images/60/large/gate_io_logo1.jpg?1706864280",
    },
    {
      key: "mexc",
      platformName: "MEXC",
      pair: "USDC/USDT",
      img: "https://assets.coingecko.com/markets/images/409/large/MEXC_logo_square.jpeg?1706864416",
    },
  ],
  WBTC: [
    {
      key: "binance",
      platformName: "Binance",
      pair: "WBTC/USDT",
      img: "https://assets.coingecko.com/coins/images/14108/standard/Binance-bitcoin.png?1696513829",
    },
    {
      key: "coinbase",
      platformName: "Coinbase",
      pair: "WBTC/USD",
      img: "https://assets.coingecko.com/markets/images/23/large/Coinbase_Coin_Primary.png?1706864258",
    },
    {
      key: "kraken",
      platformName: "Kraken",
      pair: "WBTC/USD",
      img: "https://assets.coingecko.com/markets/images/29/large/kraken.jpg?1706864265",
    },
    {
      key: "bitfinex",
      platformName: "Bitfinex",
      pair: "WBTC/USD",
      img: "https://assets.coingecko.com/markets/images/4/large/BItfinex.png?1706864245",
    },
    {
      key: "gateio",
      platformName: "Gate.io",
      pair: "WBTC/ USDT",
      img: "https://assets.coingecko.com/markets/images/60/large/gate_io_logo1.jpg?1706864280",
    },
    {
      key: "mexc",
      platformName: "MEXC",
      pair: "WBTC/USDT",
      img: "https://assets.coingecko.com/markets/images/409/large/MEXC_logo_square.jpeg?1706864416",
    },
  ],
  WETH: [
    {
      key: "binance",
      platformName: "Binance",
      pair: "WETH/USDT",
      img: "https://assets.coingecko.com/coins/images/14108/standard/Binance-bitcoin.png?1696513829",
    },
    {
      key: "coinbase",
      platformName: "Coinbase",
      pair: "WETH/USD",
      img: "https://assets.coingecko.com/markets/images/23/large/Coinbase_Coin_Primary.png?1706864258",
    },
    {
      key: "kraken",
      platformName: "Kraken",
      pair: "WETH/USD",
      img: "https://assets.coingecko.com/markets/images/29/large/kraken.jpg?1706864265",
    },
    {
      key: "bitfinex",
      platformName: "Bitfinex",
      pair: "WETH/USD",
      img: "https://assets.coingecko.com/markets/images/4/large/BItfinex.png?1706864245",
    },
    {
      key: "gateio",
      platformName: "Gate.io",
      pair: "WETH/ USDT",
      img: "https://assets.coingecko.com/markets/images/60/large/gate_io_logo1.jpg?1706864280",
    },
    {
      key: "mexc",
      platformName: "MEXC",
      pair: "WETH/USDT",
      img: "https://assets.coingecko.com/markets/images/409/large/MEXC_logo_square.jpeg?1706864416",
    },
  ],
};

const ExchangeTableModal = ({
  token = "",
  priceOtherPlatform = null,
  closeModal,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Exchange Rates</h2>
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700"
          >
            ✖
          </button>
        </div>
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-300 p-2">Exchange</th>
              <th className="border border-gray-300 p-2">Pair</th>
              <th className="border border-gray-300 p-2">Price</th>
            </tr>
          </thead>
          <tbody>
            {priceOtherPlatform &&
              exchangeData[token].map((exchange) => {
                const price = priceOtherPlatform[exchange.key];
                if (price !== null && price !== undefined) {
                  return (
                    <tr key={exchange.key} className="hover:bg-gray-50">
                      <td className="border border-gray-300 p-2 flex items-center space-x-2">
                        <img
                          src={exchange.img}
                          alt={`${exchange.img} logo`}
                          className="w-6 h-6 rounded-full"
                        />
                        <p>{exchange.platformName}</p>
                      </td>
                      <td className="border border-gray-300 p-2">
                        {exchange.pair}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {formatCurrencyWithDecimalString(price, "USD", 4)}
                      </td>
                    </tr>
                  );
                }
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExchangeTableModal;
