import { useMode } from "ModeContext";
import { useEffect, useState } from "react";
import ContentHeader from "./ContentHeader";
import ContentBalances from "./ContentBalances";
import ContentReports from "./ContentReports";
import ContentBridge from "./ContentBridge";

const Content = ({ constants = null }) => {
  const { mode } = useMode();
  const [selectedTab, setselectedTab] = useState(null);
  const defaultSelectTabIndex = () => {
    if (!constants) return null;
    if (constants.show_current_balances_btn) return 0;
    if (constants.show_reports_reasurves_btn) return 1;
    if (constants.show_bridges_btn) return 2;
  };
  useEffect(() => {
    setselectedTab(defaultSelectTabIndex());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constants]);
  const contentStuff = () => {
    switch (selectedTab) {
      case 0:
        return <ContentBalances />;
      case 1:
        return <ContentReports />;
      case 2:
        return <ContentBridge />;
      default:
        return <></>;
    }
  };

  return (
    <div className={`pb-16 w-full bg-white pt-32 md:px-12 px-5 z-10`}>
      <ContentHeader
        selectedTab={selectedTab}
        setselectedTab={setselectedTab}
        constants={constants}
      />
      <div>{contentStuff()}</div>
    </div>
  );
};

export default Content;
