export const formatCurrencyString = (number, currencyType = "USD") => {
  if (number === null || number === undefined) return "";
  // Create a formatter for the desired locale and currency
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyType,
    maximumFractionDigits: 0, // No decimal places
    minimumFractionDigits: 0, // Ensure no decimal places
  });

  // Format the number
  return formatter.format(number); // Output: $1,234,567.89
};

export const formatCurrencyWithDecimalString = (
  number,
  currencyType = "USD",
  decimal = 2
) => {
  if (number === null || number === undefined) return "";
  // Create a formatter for the desired locale and currency
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyType,
    maximumFractionDigits: decimal, // No decimal places
    minimumFractionDigits: decimal, // Ensure no decimal places
  });

  // Format the number
  return formatter.format(number); // Output: $1,234,567.89
};

export const formatThreeComaString = (number) => {
  if (number === null || number === undefined) return "";
  // Create a formatter for the desired locale and currency
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 0, // No decimal places
    minimumFractionDigits: 0, // Ensure no decimal places
  });

  // Format the number
  return formatter.format(number); // Output: $1,234,567.89
};

const defaultDigitAmount = 2;
/**
 *
 * @param {*} input
 * @param {Number} digitAmount
 * @returns {string}
 */
export const fixDigitFilter = (input, digitAmount = defaultDigitAmount) => {
  return Number(input).toFixed(digitAmount);
};
