import Content from './Content';
import Title from './Title';

const NexusProCoin = () => {
  return (
    <div className={`bg-[#263238] h-fit flex flex-col w-full pt-[90px]`}>
      <Title />
      <Content />
    </div>
  );
};

export default NexusProCoin;
